import { ReactComponent as CloseIcon } from '@/assets/close.svg';
import Button from '@/components/Button/Button';
import DialogComponent from '@/components/Dialog/DialogComponent';
import { MyAccount } from '@/modules';

import { MyAccountModalType } from './type';

const MyAccountModal = ({
  onCancel,
  isOpen,
  width,
  height,
  modalClasses,
  showCloseIcon = true,
  onGotoDashboard,
}: MyAccountModalType) => {
  return (
    <DialogComponent
      open={isOpen}
      onCancel={onCancel}
      children={
        <>
          {showCloseIcon && (
            <div className="absolute right-2 top-2 z-10 flex justify-end  pr-[16px] ">
              <Button variant="icon" icon={CloseIcon} onPress={onCancel} />
            </div>
          )}
          <MyAccount
            cardWrapperClasses="pt-0 lg:w-full lg:pt-[0]"
            cardContainerClasses="max-w-full"
            cardTabPanelClasses="bg-unset"
            onGotoDashboard={onGotoDashboard}
          />
        </>
      }
      width={` w-[95vw] max-w-[1200px]  ${width ? width : ''}`}
      height={`h-[90vh] max-h-[90vh]  md:h-auto  ${height ? height : ''}`}
      dialogClasses="z-[45]"
      modalClasses={`px-[16px] py-6 sm:px-12 rounded-[8px] relative ${
        modalClasses ? modalClasses : ''
      }`}
    />
  );
};

export default MyAccountModal;
