import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import Button from '@/components/Button/Button';

import { Status } from '@/components/ui';
import { Tag } from '@/components/ui/Tag/Tag';
import { TRIAL_DAYS } from '@/utils/constants';
import { DiscountBanner } from '../components/Banners/DiscountBanner';
import SubscriptionPlan from '../SubscriptionPlan/SubscriptionPlan';
import { SubscriptionPlanType } from '../types';

type SubscriptionPlanGroupProps = {
  plans: SubscriptionPlanType[];
  selectedPlan: string | null;
  recommendedPlan: string | null;
  onSelectSubscriptionPlan: (plan: string) => void;
  confirmPlanHandler: () => void;
  onCancel: () => void;
  choosePlanTitle: string;
  subTitle?: string | ReactNode;
  subTitleStyle?: string;
  discount?: number | null;
  shouldShowFreeTrialBadge?: boolean;
};

const SubscriptionPlanGroup = ({
  plans,
  selectedPlan,
  onSelectSubscriptionPlan,
  recommendedPlan,
  confirmPlanHandler,
  choosePlanTitle = 'Your free trial is over!',
  subTitle,
  onCancel,
  subTitleStyle,
  discount,
  shouldShowFreeTrialBadge,
}: SubscriptionPlanGroupProps) => {
  return (
    <div className="flex h-full flex-col justify-center gap-5">
      <div>
        <div className="flex w-full items-center justify-between">
          <div className="gap-x-small flex w-full flex-wrap items-center justify-between">
            <h2 className="font-F37Bolton-Medium text-2xl sm:text-2xl lg:text-4xl">
              {choosePlanTitle}
            </h2>
            {discount && shouldShowFreeTrialBadge && (
              <Tag
                tagStyle="default"
                tagType="neutral"
                children="90-day free trial"
                className="border !border-emerald-500"
              />
            )}
          </div>
          {shouldShowFreeTrialBadge && !discount && (
            <Status
              variant="completed"
              className="p-small rounded-x-small ml-auto border border-emerald-500 text-[13px]"
            >
              {TRIAL_DAYS}-day free trial
            </Status>
          )}
        </div>
        {subTitle && (
          <p className={twMerge('text-sm sm:text-xl lg:text-base', subTitleStyle)}>{subTitle}</p>
        )}
      </div>
      {discount && <DiscountBanner />}
      <div className="flex flex-col gap-3">
        {plans.map(plan => (
          <SubscriptionPlan
            key={plan.name}
            {...plan}
            isSelected={plan.name === selectedPlan}
            isRecommended={recommendedPlan === plan.name}
            amount={plan.price}
            planName={plan.name}
            maxEmployee={plan.metadata?.maxSeats ?? 0}
            pricingStrategy={'monthly'}
            onSelectSubscriptionPlan={() => !plan.isDisabled && onSelectSubscriptionPlan(plan.name)}
            discount={discount}
          />
        ))}
      </div>
      <div className="flex gap-3 lg:hidden">
        <Button variant="tertiary" parentClasses="w-full py-2.5" height="h-auto" onPress={onCancel}>
          Cancel
        </Button>
        <Button
          variant="primary"
          parentClasses="w-full py-2.5"
          height="h-auto"
          onPress={() => confirmPlanHandler()}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionPlanGroup;
