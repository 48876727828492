import moment from 'moment';
import { useMemo } from 'react';

import { SubscriptionInfo } from '@/redux/dto/auth';
import { AVAILABLE_SUBSCRIPTIONS, FREE_3_MONTH_TRIAL_SUBSCRIPTION } from '@/utils/constants';

import { checkActiveFreeTrialInAccount } from '@/utils/helpers';
import { SubscriptionBillingCardProps } from './SubscriptionBillingCard';

type UseSubscriptionBillingCardType = {
  subscriptionData: SubscriptionInfo['activeSubscriptionAgreement'];
  usageStates: SubscriptionInfo['usageStats'];
  subscriptionInformation: SubscriptionInfo;
};

const useSubscriptionBillingCard = ({
  subscriptionData,
  usageStates,
  subscriptionInformation,
}: UseSubscriptionBillingCardType) => {
  const formattedSubscriptionData = useMemo(() => {
    if (!subscriptionData) {
      return null;
    }

    const {
      plan,
      nextProcessingDate,
      price,
      status,
      startDate,
      endDate,
      scheduledToBeCancelled,
      discountCodes,
    } = subscriptionData;

    const planEndDate = moment(endDate);
    const isEndDatePassed = planEndDate.isBefore(moment());
    const { product, amount, pricingStrategy } = price || {};
    const isFreeTrial = plan === FREE_3_MONTH_TRIAL_SUBSCRIPTION;

    const { numOfActiveContractors, numOfActiveEmployees } = usageStates || {};

    const totalTeamMembers = (numOfActiveContractors ?? 0) + (numOfActiveEmployees ?? 0);
    const progressType = product?.type === 'time-based' ? 'days' : 'employee';

    const planStatus =
      status === 'canceled' || scheduledToBeCancelled
        ? 'cancelled'
        : status === 'ended' || isEndDatePassed
        ? 'expired'
        : status === 'trialing'
        ? 'trialing'
        : 'active';

    const formattedMaxValue =
      (progressType === 'days'
        ? moment(endDate).diff(startDate, 'days')
        : product?.metadata?.maxSeats) ?? 0;

    const formattedCurrentValue =
      (progressType === 'days' ? moment().diff(startDate, 'days') : totalTeamMembers) ?? 0;

    const { freeTrialDaysLeftInSubscribedPlan, isSubscribedPlanHasFreeTrial } =
      checkActiveFreeTrialInAccount({ subscriptionInfo: subscriptionInformation });

    const data: SubscriptionBillingCardProps = {
      planName: plan ?? '',
      description: product?.description ?? '',
      isFreeTrial,
      isShowProgress: true,
      isUpgradePlanAvailable: plan !== AVAILABLE_SUBSCRIPTIONS.advancedPlus,
      price: amount,
      pricingStrategy,
      progressType,
      freeTrialEndDate: isFreeTrial && endDate ? endDate : null,
      currentValue: formattedCurrentValue,
      status: planStatus,
      maxValue: formattedMaxValue,
      nextInvoiceDate: nextProcessingDate,
      discountCodes: discountCodes,
      scheduledToBeCancelled: scheduledToBeCancelled,
      freeTrialDaysLeftInSubscribedPlan,
      isSubscribedPlanHasFreeTrial,
      subscriptionAgreements: subscriptionInformation.subscriptionAgreements,
      subscriptionData: subscriptionData,
    };
    return data;
  }, [subscriptionData, subscriptionInformation]);

  return { formattedSubscriptionData };
};

export default useSubscriptionBillingCard;
