import moment from 'moment';

import { ReactComponent as SubscriptionIllustration } from '@/assets/illustration/subscriptionIllustration5.svg';
import Button from '@/components/Button/Button';
import { Discount } from '@/redux/dto/auth';

type PaymentSuccessProps = {
  planName: string;
  amount: number;
  billingDate?: string;
  onConfirm: () => void;
  nextProcessingDate: string;
  isPlanActiveWithFreeTrial?: boolean;
  isSavingConfirmation?: boolean;
  discount: Discount[];
};

const PaymentSuccess = ({
  planName,
  amount,
  nextProcessingDate,
  discount,
  onConfirm,
  isPlanActiveWithFreeTrial,
  isSavingConfirmation,
}: PaymentSuccessProps) => {
  const billingDate = nextProcessingDate && moment(nextProcessingDate).format('DD/MM/YYYY');

  const title = isPlanActiveWithFreeTrial
    ? 'Your free trial is now active!'
    : 'Your plan is now active!';

  const renderDescription = () => {
    if (isPlanActiveWithFreeTrial) {
      return (
        <p className="text-gray-400">
          You're now subscribed to the <span className="capitalize">{planName}</span> plan. You'll
          be charged <span>${amount ?? ''}</span> after the 90 day free trial is over
          <span className="ml-1">{billingDate && `i.e., on ${billingDate}.`}</span>
        </p>
      );
    }
    return (
      <p className="text-gray-400">
        Your trial has ended, and you're now subscribed to the{' '}
        <span className="capitalize">{planName}</span> plan. You'll be charged{' '}
        <span>
          $
          {discount?.length
            ? (amount * ((100 - discount[0].discount) / 100)).toFixed(2)
            : amount ?? ''}
        </span>
        <span>{billingDate && ` on ${billingDate}.`}</span>
      </p>
    );
  };

  return (
    <div className="flex flex-col items-center gap-6">
      <SubscriptionIllustration />

      <div className="flex flex-col gap-1 text-center">
        <p className="font-F37Bolton-Medium text-4xl">{title}</p>
        {renderDescription()}
      </div>

      <div className="flex w-full flex-col items-center gap-2">
        <Button
          variant="primary"
          parentClasses="flex gap-2"
          width="w-full max-w-[420px]"
          onPress={() => onConfirm()}
          isLoading={isSavingConfirmation}
        >
          Got it
        </Button>
        <div className="flex flex-wrap justify-center text-sm text-gray-300">
          Have questions? Our{' '}
          <Button
            variant="text"
            width="w-auto"
            parentClasses="text-emerald-500 mx-1"
            height="h-auto"
            onPress={() => {
              onConfirm();
              window.fcWidget.open();
            }}
          >
            support team
          </Button>
          is here to help!
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
