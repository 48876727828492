import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useAccountInfo, useBillingInfo } from '@/hooks';
import { RootState } from '@/redux/store';
import * as routes from '@/routes/routes';
import { checkAccountForSubscriptionReminder } from '@/utils/helpers';

const useDashBoardLayout = () => {
  const location = useLocation();
  const [isShowingDayReminderPopup, setIsShowingDayReminderPopup] = useState<boolean>(false);

  // These routes have new design with white background
  const updatedDesignRoutes = [
    routes.DASHBOARD,
    routes.TAX_LIABILITY,
    routes.TAX_LIABILITY_REPORT.replace(':scheduleType', ''),
    routes.COMPANY,
    routes.RUN_PAYROLL,
    routes.PAY_CONTRACTORS,
    routes.COMPANY,
    routes.PAYROLL_SETTINGS,
    routes.TEAM_MEMBERS,
  ];

  const { isLoading: isLoadingAuth0 } = useAuth0();
  const { isAccountLoading, account } = useAccountInfo();
  const { isBillingInfoLoading } = useBillingInfo();

  const isLoading = isAccountLoading || isBillingInfoLoading || isLoadingAuth0;

  const subscriptionInfo = useSelector((state: RootState) => state.auth.subscriptionInfo);
  const bannerInfo = checkAccountForSubscriptionReminder({ subscriptionInfo, account });
  const { remainingDays, isFreeTrailOver, isShowingPayBillModal } = bannerInfo || {};

  useEffect(() => {
    const todayDate = moment().format('MM-DD-YY');
    const isAlreadyShowedPopup = sessionStorage.getItem(`subscriptionReminder-${todayDate}`);
    if (isAlreadyShowedPopup) return;
    if (isShowingPayBillModal) {
      setIsShowingDayReminderPopup(true);
    } else {
      setIsShowingDayReminderPopup(false);
    }
  }, [isShowingPayBillModal]);

  const toggleReminderPopup = () => {
    setIsShowingDayReminderPopup(!isShowingDayReminderPopup);
  };

  return {
    updatedDesignRoutes,
    isLoading,
    isShowingDayReminderPopup,
    toggleReminderPopup,
    remainingDays,
    isFreeTrailOver,
    location,
  };
};

export default useDashBoardLayout;
